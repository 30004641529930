/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

// Navigation Item
.fuse-vertical-navigation-item-icon svg {
    color: #363636;
}

.mat-radio-label.required::after {
    content: '*';
    color: #EB5656;
    margin-left: 2px;
}

.mat-mdc-form-field-required-marker {
    color: #EB5656;
}

.mat-calendar-period-button {
    color: #000 !important;
}

.mat-calendar-previous-button,
.mat-calendar-next-button {
    color: rgba(0, 0, 0, .54) !important;
}

input[type="time"]::-webkit-calendar-picker-indicator:hover {
    cursor: pointer;
}

input[type="time"]:hover {
    cursor: pointer;
}

.mdc-checkbox__background {
    border-color: #008080 !important;
    background-color: #ffff !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
    color: #008080 !important;
}

::ng-deep .mdc-radio__outer-circle {
    border-color: #008080 !important;
}

::ng-deep .mdc-radio__inner-circle {
    border-color: #008080 !important;
}

.mat-pseudo-checkbox-full {
    color: #008080 !important;
    background: #fff;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
    background: #fff !important;
    border-color: #008080 !important;
}

.mat-pseudo-checkbox-full::after {
    color: #008080 !important;
}

.swal2-confirm button {
    padding: 0 3rem !important;
}

.swal2-success-line-tip {
    background-color: #008080 !important;
}

.swal2-success-ring {
    border-color: #008080 !important;
    ;
}

.swal2-success-line-long {
    background-color: #008080 !important;
    ;
}

.swal2-title {
    font-size: 1.25em !important;
}

.cdk-overlay-pane:has(.mat-mdc-select-panel) {
    width: auto !important;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
    color: #0d9488 !important;
}

.secondary-btn {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    border: 1px solid #79747e !important;
    border-radius: 1.5rem !important;
    height: 2.5rem !important;
    background-color: white;
}

.secondary-btn:hover {
    background-color: #1e293b0d;
    border: 1px solid #1e293b0d !important;
}

.ellipsis-active {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

input[disabled] {
    opacity: 0.85 !important;
}

/* Hide arrows for number input */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// For body to always give space for body scroll to fix Flickering issue on route change.
html {
    overflow: scroll;
}

button:disabled {
    opacity: 1;
}
